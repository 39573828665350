var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-vehicle-damages"},[_c('v-card-text',{staticClass:"text-right"},[_c('o-damage-modal',{attrs:{"vehicle":_vm.vehicle},on:{"update":_vm.fetchData},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","color":"success"}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Dodaj szkodę")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)]}}])})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.state.options,"server-items-length":_vm.state.total,"loading":_vm.loading},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [(item.createdAt)?_c('span',[_vm._v(_vm._s(_vm.$moment(item.createdAt).format('DD.MM.YYYY / HH:mm')))]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.description)?_c('div',{staticClass:"my-2 pa-2 grey lighten-4 rounded",domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.entities(item.description)))}}):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"small":"","label":""}},[_vm._v(_vm._s(_vm.getStatusText(item.status)))]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('o-damage-edit',{attrs:{"id":item.id},on:{"update":_vm.fetchData},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }