



































import useString from "@/use/string";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    ODamageModal: () => import("@/components/organisms/o-damage-modal.vue"),
    ODamageEdit: () => import("@/components/organisms/o-damage-edit.vue"),
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    vehicle: {
      type: Object,
      required: true,
    },
  },
  setup(props, { root }) {
    const { entities, nl2br } = useString();

    const axiosInstance = computed(
      () => root.$store.getters["api/getInstance"] as AxiosInstance
    );

    const loading = ref(false);

    const damages = ref([]);

    const headers = computed(() => [
      { text: "Data", value: "createdAt", sortable: false, width: 200 },
      { text: "Opis", value: "description", sortable: false, width: 500 },
      { text: "Status", value: "status", sortable: false, width: 200 },
      { text: "", value: "actions", align: "right", width: 1, sortable: false },
    ]);

    const items = computed(() => damages.value);

    const state = reactive({
      total: 0,
      options: {
        page: 1 as number,
        itemsPerPage: 10 as number,
        sortBy: [] as string[],
        sortDesc: [] as boolean[],
        multiSort: false as boolean,
        mustSort: false as boolean,
      },
    });

    const fetchData = async () => {
      loading.value = true;

      await axiosInstance.value
        .get(`damage`, {
          params: {
            vehicleId: props.id,
            page: state.options.page ?? 1,
            itemsPerPage: state.options.itemsPerPage ?? 10,
          },
        })
        .then(({ data }) => {
          damages.value = data.damages;
          state.total = data.total;
        })
        .catch(() => {
          damages.value = [];
          state.total = 0;
        })
        .finally(() => (loading.value = false));
    };

    onMounted(fetchData);
    watch(() => state.options, fetchData, { deep: true });

    const statuses = [
      { text: "W trakcie", value: "in_progress", color: "primary" },
      { text: "Zakończone", value: "completed", color: "success" },
    ];

    const getStatusColor = (status: string) =>
      statuses.find((s) => s.value === status)?.color ?? undefined;

    const getStatusText = (status: string) =>
      statuses.find((s) => s.value === status)?.text ?? undefined;

    return {
      entities,
      nl2br,
      loading,
      headers,
      items,
      state,
      fetchData,
      statuses,
      getStatusColor,
      getStatusText,
    };
  },
});
